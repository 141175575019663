import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme, Theme } from '@mui/material/styles';
import { Box, Container, CssBaseline, Typography, useMediaQuery } from '@mui/material';

// project imports
import Sidebar from './Sidebar';

import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import Review from 'views/pages/Feedback/Review';
import useAuth from 'hooks/useAuth';
import { accountSettingSelector, resetShowDialogOnProfileUpdate } from '../../store/slices/accountSetting';
import { resetShowDialogOnAddUser, userSelector } from '../../store/slices/user';
import { adminSelector, resetShowDialogOnAddAdmin } from '../../store/slices/admin';

// assets

interface MainStyleProps {
    theme: Theme;
    open: boolean;
    layout: string;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    padding: 0,
    marginRight: 0,
    backgroundColor: '#F5F7F8',
    minHeight: '100vh',
    height: '100%',
    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - 72) : '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 0
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginTop: 0
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            marginRight: '10px',
            marginTop: 0
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? '20px' : 0,
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.up('md')]: {
            marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 0
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            marginTop: 0
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginTop: 0
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { drawerType, container, layout } = useConfig();
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
    const { user } = useAuth();
    const { showDialogOnProfileUpdate } = useSelector(accountSettingSelector);
    const { showDialogOnAddUser } = useSelector(userSelector);
    const { showDialogOnAddAdmin } = useSelector(adminSelector);

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            dispatch(openDrawer(true));
        } else {
            dispatch(openDrawer(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerType]);

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            dispatch(openDrawer(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (matchDownMd) {
            dispatch(openDrawer(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    useEffect(() => {
        if (user?.showRatingDialog && (showDialogOnProfileUpdate || showDialogOnAddUser || showDialogOnAddAdmin)) {
            setOpenFeedbackModal(true);
        }
    }, [user?.showRatingDialog, showDialogOnProfileUpdate, showDialogOnAddUser, showDialogOnAddAdmin]);

    // const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

    // const header = (
    //     <Toolbar sx={{ p: condition ? '10px' : '16px' }}>
    //         <Header child={<Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />} />
    //     </Toolbar>
    // );

    return (
        <>
            {user?.isAdmin && (
                <Box
                    sx={{
                        position: 'sticky',
                        zIndex: 20000,
                        top: 0,
                        left: drawerWidth,
                        bgcolor: 'green',
                        width: '100%'
                    }}
                >
                    <Typography align="center" sx={{ color: '#fff' }}>
                        Logged in as BrightUp Admin with consent from {user?.firstName} {user?.lastName} ({user?.email})
                    </Typography>
                </Box>
            )}
            <Box sx={{ display: 'flex', bgcolor: '#fff' }}>
                <CssBaseline />

                {/* header */}
                {/* <AppBar enableColorOnDark position="fixed" color="transparent" elevation={0} sx={{}}>
                {header}
            </AppBar> */}

                {/* horizontal menu-list bar */}
                {/* {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && <HorizontalBar />} */}

                {/* drawer */}

                {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && <Sidebar />}

                {/* main content */}
                <Main theme={theme} open={drawerOpen} layout={layout}>
                    <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
                        {/* breadcrumb */}

                        <Outlet />
                    </Container>
                </Main>
            </Box>
            <Review
                openModal={openFeedbackModal}
                handleCloseModal={() => {
                    setOpenFeedbackModal(false);
                    resetShowDialogOnAddUser();
                    resetShowDialogOnAddAdmin();
                    resetShowDialogOnProfileUpdate();
                }}
            />
        </>
    );
};

export default MainLayout;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconKey, IconBug } from '@tabler/icons';
import PeopleIcon from '@mui/icons-material/People';

import { NavItemType } from 'types';

// constant
const icons = {
    IconKey,
    IconBug,
    PeopleIcon
};

// ==============================|| EXTRA manageUsers MENU ITEMS ||============================== //

const manageUsers: NavItemType = {
    id: 'manage-users',
    icon: icons.IconKey,
    type: 'group',
    children: [
        {
            id: 'manage-users',
            title: <FormattedMessage id="manage-users" />,
            type: 'collapse',
            icon: icons.PeopleIcon,
            children: [
                {
                    id: 'user-list',
                    title: <FormattedMessage id="user-list" />,
                    type: 'item',
                    url: '/manage-users/user-list'
                },

                {
                    id: 'admin-list',
                    title: <FormattedMessage id="admin-list" />,
                    type: 'item',
                    url: '/manage-users/admin-list'
                }
            ]
        }
    ]
};

export default manageUsers;

// project import
import other from './other';
// eslint-disable-next-line import/no-unresolved
import manageUsers from './manageUsers';
import accountSetting from './accountSetting';

// types
import { NavItemType } from 'types';
// import feedback from './feedback';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [other, manageUsers, accountSetting]
};

export default menuItems;

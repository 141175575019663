/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { getRefreshToken, setRefreshToken, setSession } from 'contexts/JWTContext';

const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000
});

const requestArray: any = [];

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => {
        if (requestArray.length !== 0) {
            requestArray.forEach((x: any, i: any) => {
                if (response.config.url === x.url) {
                    requestArray.splice(i, 1);
                }
            });
        }
        console.log('response', response);
        return response;
    },
    (error) => {
        if (error.response.status === 401 || error.response.status === 0) {
            (async () => {
                const originalConfig = error.config;
                requestArray.push(originalConfig);

                if (!originalConfig._retry) {
                    originalConfig._retry = true;

                    const refreshToken = await getRefreshToken();

                    return axiosServices
                        .post('/auth/refresh-token', {
                            refreshToken
                        })
                        .then(async (res) => {
                            const response = res.data;
                            if (res.status === 200) {
                                await setSession(response.accessToken);
                                await setRefreshToken(response.refreshToken);
                                if (requestArray.length !== 0) {
                                    requestArray.forEach((x: any) => {
                                        try {
                                            axios.defaults.headers.common.Authorization = `Bearer ${response.accessToken}`;
                                            axiosServices.defaults.headers.common.Authorization = `Bearer ${response.accessToken}`;
                                            axiosServices(x);
                                        } catch (e: any) {
                                            // Promise.reject((e.response && e.response.data) || e);
                                        }
                                    });
                                }
                                axiosServices(originalConfig);
                                console.log('Original request retried successfully after token refresh:', originalConfig);
                                requestArray.length = 0;
                            }
                        })
                        .catch(() => {
                            // alert('Something went wrong, logging out!');
                            setRefreshToken(null);
                            setSession(null);
                        });
                }
            })();
        } else if ((error?.message ?? '') === 'Network Error' || `${error}`.includes('[AxiosError: Network Error]')) {
            localStorage.removeItem('serviceToken');
            localStorage.clear();
            delete axios.defaults.headers.common.Authorization;
            alert('Internet not available. Logging out!');
            window.location.reload();
            return Promise.reject(error);
        }

        return Promise.reject(error.response && error.response.data);
    }
);

export default axiosServices;

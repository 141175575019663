import { openSnackbar } from 'store/slices/snackbar';
// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

// project imports
import axios from 'utils/axios';

// types

export const getCheckList = createAsyncThunk('dashboard/getCheckList', async ({ clientId }: any, { rejectWithValue }) => {
    try {
        const response = await axios.get(`client/checklist/${clientId}`);
        const { data } = response;
        return data;
    } catch (e: any) {
        return rejectWithValue(e);
    }
});

export const editCheckList = createAsyncThunk(
    'dashboard/editCheckList',
    async ({ value, clientId }: any, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.put(`client/checklist/${clientId}`, { ...value });
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Updated.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(getCheckList({ clientId }));
            return data;
        } catch (e: any) {
            console.log('e', e);
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

// ----------------------------------------------------------------------

const apiResponse = {
    data: [],
    isFetching: false,
    isError: false,
    isSuccess: false
};

const initialState: any = {
    checkList: apiResponse
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCheckList.pending, (state: any) => {
                state.checkList.isFetching = true;
            })
            .addCase(getCheckList.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.checkList.data = payload;
                state.checkList.isFetching = false;
            })

            .addCase(getCheckList.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.checkList.isError = true;
                state.checkList.isFetching = false;
                state.checkList.errorMessage = payload;
            });
    }
});

// Reducer
export default dashboardSlice.reducer;

export const dashboardSelector = (state: RootState) => state.dashboard;

// ----------------------------------------------------------------------

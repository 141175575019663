// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

// project imports
import axios from 'utils/axios';
import { openSnackbar } from './snackbar';

// types

export const getAllUsers = createAsyncThunk('client/users/roles', async ({ clientId }: any, { rejectWithValue }) => {
    try {
        const response = await axios.get(`client/users/roles/${clientId}/?role=CLIENT_USER&precise=1`);
        const { data } = response;
        return data;
    } catch (e: any) {
        return rejectWithValue(e);
    }
});

export const getAllAdmins = createAsyncThunk('client/users/roles-admin', async ({ clientId }: any, { rejectWithValue }) => {
    try {
        const response = await axios.get(`client/users/roles/${clientId}?role=CLIENT_ADMIN`);
        const { data } = response;
        return data;
    } catch (e: any) {
        return rejectWithValue(e);
    }
});

export const getAdminListForPrimaryAdmin = createAsyncThunk(
    'admin/getAdminListForPrimaryAdmin',
    async ({ clientId }: any, { rejectWithValue }) => {
        try {
            const response = await axios.get(`client/admin-list/${clientId}`);
            const { data } = response;
            return data;
        } catch (e: any) {
            return rejectWithValue(e);
        }
    }
);

export const createAdmin = createAsyncThunk(
    'client/users/assign-roles',
    async ({ users, clientId }: any, { rejectWithValue, dispatch, getState }) => {
        try {
            let roleAssignments: any[] = [];
            users.forEach(function (user: any) {
                roleAssignments = [...roleAssignments, { uid: user.uid }];
            });
            const response = await axios.post(`client/users/assign-roles/${clientId}`, { roleAssignments });
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Client admin created successfully.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(getAllAdmins({ clientId }));
            dispatch(getAllUsers({ clientId }));
            return data;
        } catch (e: any) {
            console.log('e', e);
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const deleteAdmin = createAsyncThunk('delete/delete-admin', async ({ userId, clientId }: any, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.put(`client/remove-admin/${clientId}`, { userId });
        const { data } = response;

        dispatch(
            openSnackbar({
                open: true,
                message: data?.msg,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        //call the admin list api for when we delete admin and list update
        dispatch(getAllAdmins({ clientId }));
        //call the user list api for to update the list from which we create admin
        dispatch(getAllUsers({ clientId }));

        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                severity: 'error',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});

// ----------------------------------------------------------------------

const initialState: any = {
    allUsers: {
        data: [],
        isFetching: false,
        isError: false,
        isSuccess: false
    },
    allAdmin: {
        data: [],
        isFetching: false,
        isError: false,
        isSuccess: false
    },
    adminListForPrimaryAdmin: {
        data: [],
        isFetching: false,
        isError: false,
        isSuccess: false
    },
    createAdmin: {
        data: [],
        isFetching: false,
        isError: false,
        isSuccess: false
    },
    adminDelete: {
        data: [],
        isFetching: false,
        isError: false,
        isSuccess: false
    },
    showDialogOnAddAdmin: false
};

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        resetShowDialogOnAddAdmin: (state: any) => {
            state.showDialogOnAddAdmin = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsers.pending, (state: any) => {
                state.allUsers.isFetching = true;
            })
            .addCase(getAllUsers.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.allUsers.isFetching = false;
                state.allUsers.data = payload;
            })

            .addCase(getAllUsers.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.allUsers.isError = true;
                state.allUsers.isFetching = false;
                state.allUsers.errorMessage = payload;
            })
            .addCase(getAllAdmins.pending, (state: any) => {
                state.allAdmin.isFetching = true;
            })
            .addCase(getAllAdmins.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.allAdmin.data = payload;
                state.allAdmin.isFetching = false;
                state.allAdmin.isSuccess = true;
            })

            .addCase(getAllAdmins.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.allAdmin.isError = true;
                state.allAdmin.isFetching = false;

                state.allAdmin.errorMessage = payload;
            })
            .addCase(getAdminListForPrimaryAdmin.pending, (state: any) => {
                state.adminListForPrimaryAdmin.isFetching = true;
            })
            .addCase(getAdminListForPrimaryAdmin.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.adminListForPrimaryAdmin.data = payload;
                state.adminListForPrimaryAdmin.isFetching = false;
                state.adminListForPrimaryAdmin.isSuccess = true;
            })

            .addCase(getAdminListForPrimaryAdmin.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.adminListForPrimaryAdmin.isError = true;
                state.adminListForPrimaryAdmin.isFetching = false;

                state.adminListForPrimaryAdmin.errorMessage = payload;
            })
            .addCase(deleteAdmin.pending, (state: any) => {
                state.adminDelete.isSuccess = false;
            })
            .addCase(deleteAdmin.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.adminDelete.isSuccess = true;
            })

            .addCase(deleteAdmin.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.adminDelete.isSuccess = false;
            })
            .addCase(createAdmin.pending, (state: any) => {
                state.createAdmin.isSuccess = false;
                state.createAdmin.isFetching = true;
            })
            .addCase(createAdmin.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.createAdmin.isSuccess = true;
                state.createAdmin.isFetching = false;
                state.showDialogOnAddAdmin = true;
            })

            .addCase(createAdmin.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.createAdmin.isSuccess = false;
                state.createAdmin.isFetching = false;
            });
    }
});

// Reducer
export default adminSlice.reducer;
export const { resetShowDialogOnAddAdmin } = adminSlice.actions;
export const adminSelector = (state: RootState) => state.admin;

// ----------------------------------------------------------------------

// material-ui
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';

// project import
import { drawerWidth } from 'store/constant';

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    borderRight: 'none',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    zIndex: 1099,
    background: theme.palette.background.default,
    overflow: 'visible',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    })
});

const closedMixin = (theme: Theme): CSSObject => ({
    borderRight: 'none',
    borderTopRightRadius: '24px',
    borderBottomRightRadius: '24px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    zIndex: 1099,
    background: theme.palette.background.default,
    overflow: 'visible',
    width: 72,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    })
});

// ==============================|| DRAWER - MINI STYLED ||============================== //

const MiniDrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    borderRight: '0px',
    flexShrink: 0,
    borderTopRightRadius: '24px',
    borderBottomRightRadius: '24px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));

export default MiniDrawerStyled;

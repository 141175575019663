import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { FORGOT_PASSWORD, LOGIN, LOGOUT, VERIFY_CODE } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// types
import { KeyedObject } from 'types';
import { InitialLoginContextProps, JWTContextType } from 'types/auth';
import { useLocation } from 'react-router-dom';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    currentSection: ''
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

export const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

export const setRefreshToken = async (refreshToken?: string | null) => {
    if (refreshToken) {
        try {
            localStorage.setItem('refreshToken', refreshToken);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }
};
export const getRefreshToken: () => void = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        console.log('refreshToken', refreshToken);
        if (refreshToken) {
            return refreshToken;
        }
    } catch (e) {
        return getRefreshToken();
    }
    return getRefreshToken();
};
// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const init = async () => {
        try {
            const serviceToken = window.localStorage.getItem('serviceToken');
            if (serviceToken && verifyToken(serviceToken)) {
                setSession(serviceToken);

                const response = await axios.get(`/profile/my-profile`);
                const decodedToken: KeyedObject = jwtDecode(serviceToken);

                const { data } = response;
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: { ...data, organizations: data.organizations[0], isAdmin: decodedToken?.adminId ? true : false }
                    }
                });
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
        } catch (err) {
            dispatch({
                type: LOGOUT
            });
        }
    };

    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const otp = params.get('otp');
    const orgId = params.get('clientId');
    const adminId = params.get('adminId');
    const clientAdminId = params.get('clientAdminId');

    const reduxDispatch = useDispatch();

    const loginWithOTP = async () => {
        try {
            const response = await axios.post(`/client/otp-login`, { code: otp, userId: adminId, clientId: orgId, clientAdminId });
            const { token, expiry, refreshToken, userId, organizations, showRatingDialog } = response.data;
            const decodedToken: KeyedObject = jwtDecode(token);
            setSession(token);
            setRefreshToken(refreshToken);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: {
                        isAdmin: decodedToken?.adminId ? true : false,
                        id: userId,
                        expiry,
                        refreshToken,
                        token,
                        showRatingDialog,
                        organizations: organizations[0]
                    }
                }
            });
        } catch (e: any) {
            await reduxDispatch(
                openSnackbar({
                    open: true,
                    message: e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            await init();
        }
    };

    useEffect(() => {
        // Access individual parameters

        if (otp && orgId && adminId && clientAdminId) {
            logout();
            loginWithOTP();
        } else {
            init();
        }
    }, [otp, orgId, adminId, clientAdminId]);

    const login = async (email: string, password: string) => {
        const response = await axios.post('/client/login', { email, password });
        const { token, expiry, refreshToken, userId, organizations, showRatingDialog } = response.data;
        setSession(token);
        setRefreshToken(refreshToken);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user: {
                    id: userId,
                    email,
                    expiry,
                    refreshToken,
                    token,
                    showRatingDialog,
                    organizations: organizations[0]
                }
            }
        });
    };
    const updateShowRatingLocally = () => {
        init();
    };
    const setOrganizationName = (name: string) => {
        const organizations = state?.user?.organizations;
        organizations.clientName = name;
        dispatch({
            type: LOGIN,
            payload: {
                ...state,
                user: {
                    ...state.user,
                    organizations
                }
            }
        });
    };
    const acceptInvitation = async (email: string, password: string, invitationCode: string, clientId: string) => {
        const response = await axios.post('/client/users/accept', { email, password, clientId, invitationCode });
        return response;
    };
    const forgotPassword = async (email: string) => {
        try {
            const response = await axios.post('/auth/reset-password-email', { email });

            if (response) {
                dispatch({
                    type: FORGOT_PASSWORD,
                    payload: { isLoggedIn: false, currentSection: 'codeVerify' }
                });
                return response;
            }
        } catch (error) {
            return error;
        }
    };
    const resetPassword = async (data: any) => {
        try {
            const { email, password, code } = data;
            const response = await axios.post('/auth/reset-password', { email, password, code });

            if (response.status === 200) {
                dispatch({
                    type: FORGOT_PASSWORD,
                    payload: { isLoggedIn: false, currentSection: '' }
                });
            }

            return response;
        } catch (error: any) {
            if (error?.errCode === '1029') {
                dispatch({
                    type: FORGOT_PASSWORD,
                    payload: { isLoggedIn: false, currentSection: 'codeVerify' }
                });
            }
            return error;
        }
    };
    const verifyCode = async () => {
        dispatch({
            type: VERIFY_CODE,
            payload: { isLoggedIn: false, currentSection: 'reset' }
        });
    };
    const register = async (
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        clientName: string
    ) => {
        // todo: this flow need to be recode as it not verified
        // const id = chance.bb_pin();
        const response = await axios.post('/client/register', {
            email,
            password,
            firstName,
            lastName,
            phoneNumber,
            clientName
        });
        return response.data;

        // if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
        //     const localUsers = window.localStorage.getItem('users');
        //     users = [
        //         ...JSON.parse(localUsers!),
        //         {
        //             id,
        //             email,
        //             password,
        //             name: `${firstName} ${lastName}`
        //         }
        //     ];
        // }

        // window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    // eslint-disable-next-line prettier/prettier
    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                logout,
                register,
                resetPassword,
                updateProfile,
                acceptInvitation,
                forgotPassword,
                verifyCode,
                updateShowRatingLocally,
                setOrganizationName
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;

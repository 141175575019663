import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import useAuth from 'hooks/useAuth';
import { dispatch, useSelector } from 'store';
import { accountSettingSelector, createRating } from 'store/slices/accountSetting';

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //

const useStyles = makeStyles({
    btn: {
        background: 'white',
        border: '1px solid black',
        color: '#000',
        margin: '3px',
        padding: '2px',
        boxShadow: 'none',
        fontWeight: '500',
        fontSize: '20px',
        '&:hover': {
            color: '#fff',
            border: 'none'
        }
    },
    selected: {
        background: '#FB8500',
        color: '#fff',
        border: 'none'
    },
    disabledButton: {
        color: '#FB8300 !important',
        opacity: '.5'
    },
    subBtn: {
        background: '#FB8300 !important',
        color: '#fff !important',
        opacity: '.5'
    }
});

interface Props {
    openModal: boolean;
    handleCloseModal: () => void;
}

const Review = ({ openModal, handleCloseModal }: Props) => {
    const [rating, setRating] = React.useState(null);
    const [feedback, setFeedback] = React.useState('');
    const [show, setShow] = React.useState(false);
    const classes = useStyles();
    const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const {
        createRating: { isSuccess, isFetching }
    } = useSelector(accountSettingSelector);
    const { updateShowRatingLocally } = useAuth();

    const { user } = useAuth();

    const handleRating = (item: any) => {
        setRating(item);
    };

    const handleReview = async () => {
        if (feedback.length > 0) {
            await dispatch(createRating({ clientId: user?.organizations?.clientId, rating, feedback }));
            await updateShowRatingLocally();
        } else {
            await dispatch(createRating({ clientId: user?.organizations?.clientId, rating }));
            await updateShowRatingLocally();
        }
    };

    useEffect(() => {
        if (isSuccess) {
            handleCloseModal();
            setRating(null);
            setShow(false);
            setFeedback('');
        }
    }, [isSuccess]);

    return (
        <>
            <Dialog
                open={openModal}
                maxWidth="lg"
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        handleCloseModal();
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
                PaperProps={{
                    style: {
                        backgroundColor: 'white',
                        boxShadow: 'none'
                    }
                }}
            >
                {openModal && (
                    <>
                        <DialogTitle id="alert-dialog-title" sx={{ fontSize: '32px', fontWeight: '700' }}>
                            How are we doing?
                        </DialogTitle>
                        <Box sx={{ position: 'absolute', top: '2em', right: '1em', color: '#000' }}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={async () => {
                                    await dispatch(
                                        createRating({ clientId: user?.organizations?.clientId, rating: 0, feedback: '', cancel: true })
                                    );
                                    await updateShowRatingLocally();
                                    await handleCloseModal();
                                }}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <DialogContent sx={{ paddingInline: '2em', paddingBlock: '0px' }}>
                            <Typography color="#000" mt={1} mb={3} sx={{ fontSize: '20px', fontWeight: '500' }}>
                                How likely are you to recommend BrightUp to a friend or a colleague? *
                            </Typography>

                            <Grid container mt={5}>
                                {arr.map((item) => (
                                    <Fab
                                        color="primary"
                                        className={`${classes.btn} ${item === rating && classes.selected}`}
                                        aria-label="add"
                                        key={item}
                                        onClick={() => {
                                            handleRating(item);
                                        }}
                                    >
                                        {item}
                                    </Fab>
                                ))}
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', color: '#000', padding: '1em' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="body1" ml={1}>
                                        😠 0 - Very Unlikely
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', color: '#000', padding: '1em' }}>
                                    <Typography variant="body1" mr={1}>
                                        10 - Very Likely 😀
                                    </Typography>
                                </Box>
                            </Box>
                            {show && (
                                <>
                                    <Typography mb={1} sx={{ fontSize: '24px', fontWeight: '700', color: '#FB8500', marginTop: '3em' }}>
                                        Tell Us Why
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-multiline-flexible"
                                        multiline
                                        name="feedback"
                                        onChange={(e) => {
                                            setFeedback(e.target.value);
                                        }}
                                        rows={4}
                                        placeholder="Start typing your feedback here..."
                                    />
                                </>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingInline: '1.5em' }}>
                                {!show ? (
                                    <Button
                                        disabled={rating == null}
                                        variant="text"
                                        size="small"
                                        autoFocus
                                        className={`${rating == null && classes.disabledButton}`}
                                        sx={{ padding: '7px', fontWeight: '700', color: '#FB8500' }}
                                        onClick={() => {
                                            setShow(true);
                                        }}
                                    >
                                        Add additional feedback
                                    </Button>
                                ) : (
                                    <Box></Box>
                                )}
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleReview}
                                    disabled={rating == null || (show && feedback.trim() === '') || isFetching}
                                    className={`${
                                        (rating == null && classes.subBtn) || (show && feedback.trim() === '' && classes.subBtn)
                                    }`}
                                    autoFocus
                                    sx={{
                                        padding: '10px',
                                        fontWeight: '700',
                                        background: '#FB8500',
                                        boxShadow: 'none ',
                                        borderRadius: '50px',
                                        paddingInline: '30px'
                                    }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default Review;

// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

// project imports
import axios from 'utils/axios';

// types

import { openSnackbar } from './snackbar';

export const getAllUsers = createAsyncThunk('users/getAllUsers', async ({ clientId }: any, { rejectWithValue }) => {
    try {
        const response = await axios.get(`client/users/${clientId}`);
        const { data } = response;
        return data;
    } catch (e: any) {
        return rejectWithValue(e);
    }
});

export const addUsers = createAsyncThunk(
    'users/addUsers',
    async ({ users, clientId, resend = false }: any, { rejectWithValue, dispatch }) => {
        try {
            users.map((item: any) => delete item.id);

            const response = await axios.post(`client/users/invite/${clientId}`, { users });
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Invitation sent.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );

            !resend && dispatch(getAllUsers({ clientId }));
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);
export const editUser = createAsyncThunk('users/editUser', async ({ user, clientId }: any, { rejectWithValue, dispatch }) => {
    try {
        const { email, phoneNumber, department, eligibleUserId } = user;

        const obj = { email, phoneNumber, department, eligibleUserId };
        const response = await axios.put(`client/users/${clientId}`, obj);
        const { data } = response;
        dispatch(
            openSnackbar({
                open: true,
                message: data?.msg,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );

        dispatch(getAllUsers({ clientId }));
        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                severity: 'error',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});

export const deleteUser = createAsyncThunk('user/delete-user', async ({ userId, clientId }: any, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.put(`client/remove-user/${clientId}`, { userId });
        const { data } = response;

        dispatch(
            openSnackbar({
                open: true,
                message: data?.msg,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        dispatch(getAllUsers({ clientId }));

        return data;
    } catch (e: any) {
        console.log(e);
        dispatch(
            openSnackbar({
                open: true,
                message: e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                severity: 'error',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});
// ----------------------------------------------------------------------
const apiResponse = { data: [], isFetching: false, isError: false, isSuccess: false };

const initialState: any = {
    allUsers: apiResponse,
    userDelete: apiResponse,
    sendInvite: apiResponse,
    edit: apiResponse,
    showDialogOnAddUser: false
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetShowDialogOnAddUser: (state: any) => {
            state.showDialogOnAddUser = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsers.pending, (state: any) => {
                state.allUsers.isFetching = true;
            })
            .addCase(getAllUsers.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.allUsers.data = payload;
                state.allUsers.isFetching = false;
            })

            .addCase(getAllUsers.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.allUsers.isError = true;
                state.allUsers.isFetching = false;
                state.allUsers.errorMessage = payload;
            })
            .addCase(deleteUser.pending, (state: any) => {
                state.userDelete.isSuccess = false;
            })
            .addCase(deleteUser.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.userDelete.isSuccess = true;
            })

            .addCase(deleteUser.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.userDelete.isSuccess = false;
            })

            .addCase(addUsers.pending, (state: any) => {
                state.allUsers.isSuccess = false;
                state.sendInvite.isSuccess = false;
            })
            .addCase(addUsers.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.allUsers.isSuccess = true;
                state.showDialogOnAddUser = true;
                state.sendInvite.isSuccess = true;
            })

            .addCase(addUsers.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.allUsers.isSuccess = false;
                state.sendInvite.isSuccess = false;
            })
            .addCase(editUser.pending, (state: any) => {
                state.edit.isSuccess = false;
            })
            .addCase(editUser.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.edit.isSuccess = true;
            })

            .addCase(editUser.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.edit.isSuccess = false;
            });
    }
});

// Reducer

export default userSlice.reducer;

export const { resetShowDialogOnAddUser } = userSlice.actions;

export const userSelector = (state: RootState) => state.user;

// ----------------------------------------------------------------------

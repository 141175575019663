// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconAdjustments } from '@tabler/icons';

import { NavItemType } from 'types';

// constant
const icons = {
    IconAdjustments
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const accountSetting: NavItemType = {
    id: 'account-settings',
    icon: IconBrandChrome,
    type: 'group',
    children: [
        {
            id: 'account-settings',
            title: <FormattedMessage id="account-settings" />,
            type: 'item',
            url: '/account-settings',
            icon: icons.IconAdjustments,
            breadcrumbs: false
        }
    ]
};

export default accountSetting;

import { openSnackbar } from 'store/slices/snackbar';
// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

// project imports
import axios from 'utils/axios';
import { getAdminListForPrimaryAdmin } from './admin';

// types

export const getClientProfile = createAsyncThunk('client/profile/get', async ({ clientId }: any, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/client/profile/${clientId}`);
        const { data } = response;

        return data;
    } catch (e: any) {
        return rejectWithValue(e);
    }
});
export const getDepartmentList = createAsyncThunk('client/departments', async ({ clientId }: any, { rejectWithValue }) => {
    try {
        const response = await axios.get(`client/departments/${clientId}`);
        const { data } = response;
        return data;
    } catch (e: any) {
        return rejectWithValue(e);
    }
});

export const changePassword = createAsyncThunk(
    'profile/password',
    async ({ newPassword, oldPassword }: any, { rejectWithValue, dispatch }) => {
        try {
            console.log('formData', newPassword);
            const response = await axios.post('profile/password', { newPassword, oldPassword });
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.message,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const createDepartment = createAsyncThunk(
    'client/departments/create',
    async ({ myArray, clientId }: any, { rejectWithValue, dispatch }) => {
        try {
            const departments = myArray;
            const response = await axios.post(`client/departments/${clientId}`, { departments });
            const { data } = response;
            console.log('data', data);
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.msg,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const createRating = createAsyncThunk(
    'client/rating',
    async ({ rating, feedback, cancel = false, clientId }: any, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post(`client/rating/${clientId}`, { rating, feedback, cancel });
            const { data } = response;

            !cancel &&
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Thanks, we will be in touch with you shortly.',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const createFeedback = createAsyncThunk(
    'client/feedback',
    async ({ rating, feedback, clientId }: any, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post(`client/feedback/${clientId}`, { rating, feedback });
            const { data } = response;

            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Thanks, we will be in touch with you shortly.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const updateProfilePicture = createAsyncThunk(
    'account/updateProfile/picture',
    async ({ formData, clientId }: any, { rejectWithValue, dispatch }) => {
        try {
            const files = formData;

            const response = await axios.put(`client/profile/${clientId}`, files);
            const { data } = response;

            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.msg,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(getClientProfile({ clientId }));
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);
export const updateProfile = createAsyncThunk(
    'account/updateProfile',
    async ({ formData, clientId }: any, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.put(`client/profile/${clientId}`, { ...formData });
            const { data } = response;

            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.msg,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(getDepartmentList({ clientId }));
            dispatch(getClientProfile({ clientId }));
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const createPrimaryAdmin = createAsyncThunk(
    'account/createPrimaryAdmin',
    async ({ inputValue, clientId }: any, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.put(`client/change-primary-admin/${clientId}`, { userId: inputValue });
            const { data } = response;

            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.msg,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(getAdminListForPrimaryAdmin({ clientId }));
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

// ----------------------------------------------------------------------

const apiResponse = {
    data: [],
    isFetching: false,
    isError: false,
    isSuccess: false
};

const initialState: any = {
    profile: apiResponse,
    departmentList: apiResponse,
    createRating: apiResponse,
    createFeedback: apiResponse,
    updateProfileData: apiResponse,
    changePasswordData: apiResponse,
    createAdmin: apiResponse,
    showDialogOnProfileUpdate: false
};

const accountSettingSlice = createSlice({
    name: 'accountSetting',
    initialState,
    reducers: {
        resetShowDialogOnProfileUpdate: (state: any) => {
            state.showDialogOnProfileUpdate = false;
        }
    },
    extraReducers: (builder) => {
        builder

            .addCase(getDepartmentList.pending, (state: any) => {
                state.departmentList.isFetching = true;
            })
            .addCase(getDepartmentList.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.departmentList.data = payload;
                state.departmentList.isFetching = false;
            })

            .addCase(getDepartmentList.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.departmentList.isError = true;
                state.departmentList.isFetching = false;
                state.departmentList.errorMessage = payload;
            })
            .addCase(getClientProfile.pending, (state: any) => {
                state.profile.isFetching = true;
            })
            .addCase(getClientProfile.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.profile.data = payload;
                state.profile.isFetching = false;
            })

            .addCase(getClientProfile.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.profile.isError = true;
                state.profile.isFetching = false;
                state.profile.errorMessage = payload;
            })
            .addCase(createRating.pending, (state: any) => {
                state.createRating.isFetching = true;
                state.createRating.isSuccess = false;
            })
            .addCase(createRating.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.createRating.isFetching = false;
                state.createRating.isSuccess = true;
            })

            .addCase(createRating.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.createRating.isFetching = false;

                state.createRating.isSuccess = false;
            })
            .addCase(updateProfile.pending, (state: any) => {
                state.updateProfileData.isFetching = true;
                state.updateProfileData.isSuccess = false;
            })
            .addCase(updateProfile.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.updateProfileData.isFetching = false;
                state.updateProfileData.isSuccess = true;
                state.showDialogOnProfileUpdate = true;
            })

            .addCase(updateProfile.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.updateProfileData.isFetching = false;

                state.updateProfileData.isSuccess = false;
            })
            .addCase(changePassword.pending, (state: any) => {
                state.changePasswordData.isFetching = true;
                state.changePasswordData.isSuccess = false;
            })
            .addCase(changePassword.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.changePasswordData.isFetching = false;
                state.changePasswordData.isSuccess = true;
                state.showDialogOnProfileUpdate = true;
            })

            .addCase(changePassword.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.changePasswordData.isFetching = false;
                state.changePasswordData.isSuccess = false;
            })
            .addCase(createFeedback.pending, (state: any) => {
                state.createFeedback.isFetching = true;
                state.createFeedback.isSuccess = false;
            })
            .addCase(createFeedback.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.createFeedback.isFetching = false;
                state.createFeedback.isSuccess = true;
            })

            .addCase(createFeedback.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.createFeedback.isFetching = false;

                state.createFeedback.isSuccess = false;
            })
            .addCase(createPrimaryAdmin.pending, (state: any) => {
                state.createAdmin.isFetching = true;
                state.createAdmin.isSuccess = false;
            })
            .addCase(createPrimaryAdmin.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.createAdmin.isFetching = false;
                state.createAdmin.isSuccess = true;
                state.showDialogOnProfileUpdate = true;
            })

            .addCase(createPrimaryAdmin.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.createAdmin.isFetching = false;
                state.createAdmin.isSuccess = false;
            });
    }
});

// Reducer
export default accountSettingSlice.reducer;
export const { resetShowDialogOnProfileUpdate } = accountSettingSlice.actions;
export const accountSettingSelector = (state: RootState) => state.accountSetting;

// ----------------------------------------------------------------------

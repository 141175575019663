// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import userReducer from './slices/user';
import adminReducer from './slices/admin';
import clientRegistrationReducer from './slices/clientRegistration';
import accountSettingReducer from './slices/accountSetting';
import dashboardReducer from './slices/dashboard';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    user: userReducer,
    admin: adminReducer,
    clientRegistration: clientRegistrationReducer,
    accountSetting: accountSettingReducer,
    dashboard: dashboardReducer
});

export default reducer;

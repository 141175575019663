import { memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, useMediaQuery } from '@mui/material';

// third-party
// import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from '../MenuList';
import LogoSection from '../LogoSection';
import MiniDrawerStyled from './MiniDrawerStyled';

import { drawerWidth } from 'store/constant';

import { useDispatch, useSelector } from 'store';
import { Logout } from '@mui/icons-material';
import useAuth from 'hooks/useAuth';

import { borderRadius } from '@mui/system';
import { openDrawer } from 'store/slices/menu';

import ArrowCircleLeftTwoToneIcon from '@mui/icons-material/ArrowCircleLeftTwoTone';
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const { logout, user } = useAuth();
    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const logo = useMemo(
        () => (
            <Box sx={{ display: 'flex', p: 2 }}>
                <LogoSection small={!drawerOpen} />
            </Box>
        ),
        [drawerOpen]
    );
    const iconSelectedColor = theme.palette.mode === 'dark' && drawerOpen ? 'text.primary' : 'secondary.main';

    const drawer = useMemo(
        () => (
            <>
                <div
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        justifyContent: 'space-between',
                        paddingLeft: drawerOpen ? '16px' : 0,
                        paddingRight: drawerOpen ? '16px' : 0,
                        marginTop: drawerOpen ? 0 : '20px'
                    }}
                >
                    <MenuList />
                </div>
                <List sx={{ ml: drawerOpen ? 2 : 1 }}>
                    <ListItemButton
                        sx={{
                            zIndex: 1201,
                            borderRadius: `${borderRadius}px`,
                            mb: 0.5,
                            '&:hover': {
                                background: 'transparent !important'
                            },
                            '&.Mui-selected': {
                                background: 'transparent !important',
                                color: iconSelectedColor,
                                '&:hover': {
                                    color: iconSelectedColor,
                                    background: 'transparent !important'
                                }
                            }
                        }}
                        onClick={handleLogout}
                    >
                        <ListItemIcon sx={{ minWidth: 28, color: '#9BA4C2' }}>
                            <Logout />
                        </ListItemIcon>
                        {drawerOpen && (
                            <ListItemText
                                sx={{ mr: 1 }}
                                primary={
                                    <Typography color="inherit" variant={'h5'} sx={{ my: 'auto' }}>
                                        Logout
                                    </Typography>
                                }
                            />
                        )}
                    </ListItemButton>
                </List>
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd, drawerOpen]
    );

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            {/* {matchDownMd || (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
                <Drawer
                    variant={matchUpMd ? 'persistent' : 'temporary'}
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                        '& .MuiDrawer-paper': {
                            zIndex: 1099,
                            width: drawerWidth,
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none'
                        }
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    {logo}
                    {drawer}
                </Drawer>
            ) : ( */}
            <MiniDrawerStyled variant="permanent" open={drawerOpen}>
                <Stack direction={'column'}>
                    {logo}
                    {drawerOpen && (
                        <Box sx={{ height: '50px', marginLeft: 3 }}>
                            <Typography variant="h4" color={'black'}>
                                {user?.organizations?.clientName}
                            </Typography>
                        </Box>
                    )}
                    <IconButton
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        sx={{
                            position: 'absolute',
                            right: -20,
                            top: 30,
                            '&:hover': {
                                color: '#FB8500'
                            }
                        }}
                    >
                        {!drawerOpen ? <ArrowCircleRightTwoToneIcon /> : <ArrowCircleLeftTwoToneIcon />}
                    </IconButton>
                </Stack>
                {!drawerOpen && <Box sx={{ height: '50px' }} />}

                {drawer}
            </MiniDrawerStyled>
            {/* )} */}
        </Box>
    );
};

export default memo(Sidebar);

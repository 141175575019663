// action - state management
import { FORGOT_PASSWORD, LOGIN, LOGOUT, REGISTER, UPDATE_SHOW_DIALOG_LOCALLY, VERIFY_CODE } from './actions';
import { InitialLoginContextProps } from 'types/auth';

// ==============================|| ACCOUNT REDUCER ||============================== //

interface AccountReducerActionProps {
    type: string;
    payload?: InitialLoginContextProps;
}

const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    clientId: ''
};

// eslint-disable-next-line
const accountReducer = (state = initialState, action: AccountReducerActionProps) => {
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload!;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const { user } = action.payload!;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user
            };
        }
        case UPDATE_SHOW_DIALOG_LOCALLY: {
            const { user } = action.payload!;
            return {
                ...state,
                user
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null
            };
        }
        case FORGOT_PASSWORD: {
            const { currentSection } = action.payload!;
            return {
                ...state,
                currentSection
            };
        }
        case VERIFY_CODE: {
            const { currentSection } = action.payload!;
            return {
                ...state,
                currentSection
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;

// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch, RootState } from 'store';
// project imports
import axios from 'utils/axios';
import { openSnackbar } from './snackbar';

// ----------------------------------------------------------------------

export const getEmailStatus = createAsyncThunk('client/email-status', async ({ email }: any, { rejectWithValue }) => {
    try {
        const response = await axios.get(`client/email-status?email=${encodeURIComponent(email)}`);
        const { data } = response;

        return data;
    } catch (e: any) {
        return rejectWithValue(e);
    }
});

export const sendVerificationEmail = createAsyncThunk(
    'client/send-verification-email',
    async (args: undefined, { rejectWithValue, getState }) => {
        const {
            clientRegistration: {
                userData: { firstName, email }
            }
        }: any = getState();
        try {
            const response = await axios.post('client/send-verification-email', { email, firstName });
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Code Send Successful',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.msg || e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);
export const sendVerificationPhone = createAsyncThunk(
    'client/send-verification-sms',
    async (args: undefined, { rejectWithValue, getState }) => {
        const {
            clientRegistration: {
                userData: { firstName, phoneNumber }
            }
        }: any = getState();
        try {
            const response = await axios.post('client/send-verification-sms', { phoneNumber, firstName });
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Code Send Successful',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.ex || e?.ex?.ex || 'Invalid phone number',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const verfyCode = createAsyncThunk('client/verify', async ({ email, phoneNumber, code }: any, { rejectWithValue }) => {
    try {
        const response = await axios.post('client/verify', { email, phoneNumber, code });
        const { data } = response;
        console.log('data', data);
        if (email) {
            data.emailVerify = true;
        }
        dispatch(
            openSnackbar({
                open: true,
                message: 'Verification Successful.',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.msg || e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                severity: 'error',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});

export const clientRegister = createAsyncThunk('client/register', async (userData: any, { rejectWithValue }) => {
    try {
        const response = await axios.post('client/register', { ...userData });
        dispatch(
            openSnackbar({
                open: true,
                message: 'Register Successful',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        const { data } = response;

        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                severity: 'error',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});

const initialState: any = {
    userData: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        clientName: '',
        companySize: '',
        companyPhone: '',
        companyMailingAddress1: '',
        companyMailingAddress2: '',
        city: '',
        state: '',
        zip: '',
        companyCountry: '',
        password: ''
    },
    emailStatus: {},
    progress: 0
};

const cleientRegistrationSlice = createSlice({
    name: 'CleintRegistration',
    initialState,
    reducers: {
        clearUserData: (state: any) => {
            state.userData = {
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                country: '',
                clientName: '',
                companySize: '',
                companyPhone: '',
                companyMailingAddress1: '',
                companyMailingAddress2: '',
                city: '',
                state: '',
                zip: '',
                companyCountry: '',
                password: ''
            };
        },
        setUserData: (state: any, { payload }: PayloadAction<any>) => {
            const { key, value } = payload;
            state.userData[key] = value;
        },
        setProgress: (state: any, { payload }: PayloadAction<any>) => {
            state.progress = payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEmailStatus.pending, (state: any) => {
                state.emailStatus.isFetching = true;
            })
            .addCase(getEmailStatus.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.emailStatus = payload;
                state.progress = payload.exists === false ? 15 : 8;
                state.emailStatus.isFetching = false;
            })
            .addCase(getEmailStatus.rejected, (state: any, { payload }: PayloadAction<any>) => {
                state.emailStatus.isError = true;
                state.emailStatus.isFetching = false;
                state.emailStatus.errorMessage = payload;
            })
            .addCase(verfyCode.pending, (state: any) => {})
            .addCase(verfyCode.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.progress = payload?.emailVerify === true ? 28 : 34;
                state.emailStatus.isFetching = false;
            })
            .addCase(verfyCode.rejected, (state: any, { payload }: PayloadAction<any>) => {})
            .addCase(clientRegister.pending, (state: any) => {})
            .addCase(clientRegister.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
                state.progress = 100;
                state.emailStatus.isFetching = false;
            })
            .addCase(clientRegister.rejected, (state: any, { payload }: PayloadAction<any>) => {});
    }
});

export const { clearUserData, setUserData, setProgress } = cleientRegistrationSlice.actions;
export const clientRegistrationSelector = (state: RootState) => state.clientRegistration;
export default cleientRegistrationSlice.reducer;
